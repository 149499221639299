import icons from '@/Plugin/Icons/icons.vue';
// import moduleIcons from '@/Plugin/Icons/module-icons.vue';
// import systemIcons from '@/Plugin/Icons/system-icons.vue';
import { mapActions, mapGetters } from 'vuex';
import EventBus from '@/eventBus';
import i18n from '@/Plugin/Localisation/i18n';
import store from '@/store/store';
import { checkPermission } from '@/Plugin/Permissions/permissionsPlugin';
import { USER } from '@/store/auth/auth-getters';
import sspaConfig from '../single-spa-config';

export default {
    name: 'App',
    components: {
        icons,
        // moduleIcons,
        // systemIcons,
    },
    data() {
        return {
            loading: false,
            maxTry: 10,
            timeout: 1500,
            currentTry: 0,
            inited: false,
        };
    },
    watch: {
        user() {
            const token = localStorage.getItem('token');
            if (!this.inited && this.activeState && token) {
                this.registerModules();
                this.inited = true;
            }
        },
        activeState() {
            const token = localStorage.getItem('token');
            if (!this.inited && this.activeState && token) {
                this.registerModules();
                this.inited = true;
            }
        },
        mapMaxBound: {
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    const code = this.administrativeObjectData?.administrative_level !== 4 ? this.selectedAgglomeration : null;
                    EventBus.$emit('mapModule/setMapZoomAndCenter', {
                        zoom: this.mapZoom,
                        center: this.mapCenter,
                        mapMaxBounds: this.mapMaxBound,
                        code,
                        agglomerationZone: this.agglomerationZone,
                    });
                }
            },
        },
    },
    computed: {
        ...mapGetters({
            user: USER,
            mapMaxBound: 'mapMaxBounds',
            selectedAgglomeration: 'selectedAgglomeration',
            mapZoom: 'mapZoom',
            mapCenter: 'mapCenter',
            agglomerationZone: 'agglomerationZone',
            mapToken: 'mapToken',
            activeState: 'activeState',
            administrativeObjectData: 'administrativeObjectData',
        }),
    },
    methods: {
        ...mapActions(['fetchConfig']),
        registerLocalModules() {
            try {
                this.loading = true;
                sspaConfig.registerLocalModules({
                    EventBus,
                    checkPermission,
                    i18n,
                    store,
                });
            } catch (e) {
                console.error(e);
            } finally {
                this.loading = false;
            }
        },
        registerModules() {
            try {
                this.loading = true;
                sspaConfig.registerModules({
                    authToken: localStorage.getItem('token'),
                    EventBus,
                    $checkPermission: checkPermission,
                    i18n,
                    user: this.user,
                    getBbox: () => this.mapMaxBound,
                    store,
                    mapToken: this.mapToken,
                });
            } catch (e) {
                console.error(e);
            } finally {
                this.loading = false;
            }
        },
        initTheme() {
            const theme = localStorage.getItem('theme') || 'dark';
            this.$store.commit('setTheme', theme);
        },
    },
    created() {
        this.initTheme();
        this.registerLocalModules();
        this.fetchConfig();
        EventBus.$on('mapModule/getMapZoomAndCenter', (callback) => {
            const code = this.administrativeObjectData?.administrative_level !== 4 ? this.selectedAgglomeration : null;
            callback({
                zoom: this.mapZoom,
                center: this.mapCenter,
                mapMaxBounds: this.mapMaxBound,
                code,
                agglomerationZone: this.agglomerationZone,
            });
        });
    },
};
