const dateTimeFormats = {
    ru: {
        default: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        },
        short: {
            month: 'short',
            year: 'numeric',
            day: 'numeric',
        },
        long: {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        },
    },
    en: {
        default: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        },
        short: {
            month: 'short',
            year: 'numeric',
            day: 'numeric',
        },
        long: {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        },
    },
};

export default dateTimeFormats;
