<template>
    <div>
        <mIcons />
        <mapIcons />
        <sIcons />
        <oIcons />
    </div>
</template>

<script>
import mIcons from './module-icons.vue';
import sIcons from './system-icons.vue';
import oIcons from './other-icons.vue';
import mapIcons from './map-icons.vue';

export default {
    components: {
        mIcons,
        sIcons,
        oIcons,
        mapIcons,
    },
};
</script>
