import Vue from 'vue';
import VueI18n from 'vue-i18n';
import elementUiRu from 'element-ui/lib/locale/lang/ru-RU';
import elementUiEn from 'element-ui/lib/locale/lang/en';
import * as ru from './default/ru.json';
import * as en from './default/en.json';
import dateTimeFormats from './default/dateTimeFormats';

Vue.use(VueI18n);

const locale = localStorage.getItem('locale') || 'ru';

const messages = {
    ru: {
        ...ru.default,
        ...elementUiRu,
    },
    en: {
        ...en.default,
        ...elementUiEn,
    },
};

const i18n = new VueI18n({
    locale, // set locale
    fallbackLocale: 'ru', // set fallback locale
    messages, // set locale messages
    dateTimeFormats,
    silentTranslationWarn: true, // Disable no translation warning
});

export default i18n;
