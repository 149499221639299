import * as singleSpa from 'single-spa';
import {
    getModules,
} from '@/api';

export default {
    registerLocalModules(props) {
        const {
            i18n,
            store,
            checkPermission,
        } = props;

        singleSpa.registerApplication({
            name: 'header',
            app: () => import('./modules/header/index'),
            activeWhen: ['/module', '/main'],
            customProps: { i18n, store, checkPermission },
        });

        singleSpa.registerApplication({
            name: 'login',
            app: () => import('./modules/login/index'),
            activeWhen: ['/login', '/set_password', '/email_confirmation'],
            customProps: { i18n, store },
        });

        singleSpa.registerApplication({
            name: 'startPage',
            app: () => import('./modules/startPage/index'),
            activeWhen: ['/main'],
            customProps: { i18n, store, checkPermission },
        });
        singleSpa.start({
            urlRerouteOnly: true,
        });

        window.addEventListener('single-spa:before-routing-event', () => {
            console.info('before routing event occurred!');
        });

        window.addEventListener('single-spa:routing-event', () => {
            console.info('routing event occurred!');
        });

        window.addEventListener('single-spa:app-change', () => {
            console.info('Mounted apps: ', singleSpa.getMountedApps());
        });
    },
    registerModules(props) {
        getModules()
            .then(({ data: modules }) => {
                modules.forEach(({ name }) => {
                    singleSpa.registerApplication({
                        name,
                        app: () => {
                            try {
                                return System.import(`eputs-module-${name}`);
                            } catch (e) {
                                console.error(e);
                                return System.import(`eputs-module-${name}`);
                            }
                        },
                        activeWhen: [`/module/${name}`],
                        customProps: props,
                        // {
                        //     authToken: localStorage.getItem('token'), i18n, store, $checkPermission: checkPermission, EventBus, getBbox: () => store.getters.mapMaxBounds,
                        // },
                    });
                });
            })
            .catch((err) => {
                console.error(err);
            });
    },
};
