export default {
    methods: {
        goBack(goRoot = false) {
            this.goBackAction(goRoot);
        },
        goBackAction(goRoot = false) {
            const routerItems = sessionStorage.getItem('routerItems') ? JSON.parse(sessionStorage.getItem('routerItems')) : [];
            if (routerItems.length) {
                this.$route.params.goBack = true;
                let link = routerItems[0];
                if (!goRoot) {
                    link = routerItems[routerItems.length - 1];
                    routerItems.pop();
                    sessionStorage.setItem('routerItems', JSON.stringify(routerItems));
                }
                this.$router.push(link);
                return;
            }
            if (this.$route.matched && this.$route.matched.length > 1) {
                const { matched } = this.$route;
                const url = matched[matched.length - 2].redirect || matched[matched.length - 2].path;
                this.$route.params.goBack = true;
                this.$router.push(url);
                return;
            }
            this.$router.go(-1);
        },
    },
};
