/*eslint-disable*/

export const IS_LOGGED_IN = 'fast/auth/getters/isLoggedIn';
export const USER_NAME = 'fast/auth/getters/userName';
export const USER_ROLES = 'fast/auth/getters/userRoles';
export const USER = 'fast/auth/getters/user';
export const CUSTOMER_ID = 'fast/auth/getters/customerId';
export const TOKEN = 'fast/auth/getters/token';
export const USER_PERMISSIONS = 'fast/auth/getters/user/permissions';

export default {
    [IS_LOGGED_IN]: state => !!state.user,
    [USER_NAME]: (state) => {
        if (state.user) {
            return state.user.email;
        }
        return 'Аноним';
    },
    [USER_ROLES]: (state) => {
        let roles = '';
        if (state && state.user && state.user.realm_access) {
            roles = state.user.realm_access.roles;
        }
        return roles;
    },
    [USER]: state => state.user,
    [CUSTOMER_ID]: state => {
        return state.user && state.user.data? state.user.data.customer.id || '':'';
    },
    [TOKEN]: (state) => {
        const obj = {
            token: state.token,
            refresh_token: state.refreshToken,
        };
        return obj;
    },
    [USER_PERMISSIONS]: (state, getters, { config }) => {
        const { extends: additional = [] } = config;
        return state.user ? [...additional, ...state.user.permissions] : [...additional];
    },
};
