import helper from './global/helper';
import formHelper from './global/formHelper';
// import getTaskStatus from './global/getTaskStatus';
import goBack from './global/goBack';
// import editHelper from './global/editHelper';

export default {
    mixins: [
        helper,
        formHelper,
        // getTaskStatus,
        goBack,
        // editHelper,
    ],
};
