export default (nStrOut, shot) => {
  if (nStrOut === 0 || nStrOut === '0') return 0;
  if (!nStrOut) return '';
  let nStr = Math.round(nStrOut * 100) / 100;
  nStr += '';
  const x = nStr.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 && !shot ? `.${x[1]}` : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1 $2');
  }
  return x1 + x2;
};
