import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/store';
// import './single-spa-config';
// import './assets/styles/element-theme/_index.scss';
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import './assets/styles/index.scss';
import locale from 'element-ui/lib/locale/lang/ru-RU';
import i18n from '@/Plugin/Localisation/i18n';
import globalMixins from '@/mixins/globalMixins';
import {
    ElementUIFormItem,
    ElementUIMenu,
} from '@eputs-base/ui-components';
import Icon from '@eputs/icons';
import permissionsList from '@/Plugin/Permissions/permissions';
import permissionsPlugin from '@/Plugin/Permissions/permissionsPlugin';
import App from './App/App.vue';
import { LOGIN, REFRESH, LOGOUT } from './store/auth/auth-actions';
import { USER_PERMISSIONS } from './store/auth/auth-getters';
import helper from './mixins/helper';
import triad from './filters/triad';
import triadZero from './filters/triadZero';
import dateFilter from './filters/date';
import pegFormatter from './filters/pegFormatter';
import permission from './directives/permission';
import inputMask from './directives/inputMask';
import regexInput from './directives/regexInput';
import validationRules from './directives/mask';

Vue.use(VueRouter);
const router = new VueRouter({
    base: '/',
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/main',
        },
        {
            path: '/icons',
            redirect: '',
            component: () => import('@eputs/icons/dist/IconsTestPage.vue'),
        },
    ],
});

Vue.use(ElementUI, {
    locale,
    i18n: (key, value) => i18n.t(key, value),
});
Vue.component(ElementUIFormItem.name, ElementUIFormItem);
Vue.component(ElementUIMenu.name, ElementUIMenu);

Vue.use(permissionsPlugin, permissionsList);
Vue.directive('permission', permission);
Vue.directive('mask', inputMask);
Vue.directive('regexInput', regexInput);
Vue.prototype.$validRules = validationRules;
// Filters
Vue.filter('date', dateFilter);
Vue.filter('pegFormatter', pegFormatter);
Vue.filter('triad', triad);
Vue.filter('triadZero', triadZero);
Vue.prototype.$filters = Vue.options.filters;
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-debugger */

Vue.component(Icon.name, Icon);

Vue.config.productionTip = false;
Vue.mixin(helper);
Vue.mixin(globalMixins);

const appRouter = router;

Vue.directive('mask', inputMask);
store.dispatch(LOGIN).then(() => {
    Vue.prototype.$getPermission = () => {
        const { tokenParsed } = store.state;
        if (tokenParsed && tokenParsed.realm_access && tokenParsed.realm_access.roles) return tokenParsed.realm_access.roles;
        return [];
    };

    Vue.prototype.$checkPermission = (role, operator = 'and', callback) => {
        // const { tokenParsed } = store.state;
        const userRoles = store.getters[USER_PERMISSIONS];
        if (!userRoles || !userRoles.length) return false;
        // if (!tokenParsed || !tokenParsed.realm_access || !tokenParsed.realm_access.roles) return false;
        // const userRoles = tokenParsed.realm_access.roles;
        let canAccess = true;
        operator = String(operator).toLowerCase();

        if (Array.isArray(role)) {
            canAccess = operator !== 'or';
            for (let i = 0; i < role.length; i += 1) {
                const curRole = role[i];
                if (operator === 'or') {
                    if (userRoles.includes(curRole)) {
                        canAccess = true;
                        break;
                    }
                } else if (!userRoles.includes(curRole)) {
                    canAccess = false;
                    return false;
                }
            }
        } else {
            canAccess = userRoles.includes(role);
        }
        if (canAccess && callback && typeof callback === 'function') {
            callback();
        }
        return canAccess;
    };
    const app = new Vue({
        router: appRouter,
        store,
        i18n,
        template: '<App/>',
        render: (h) => h(App),
    });

    const checkAccess = (path) => {
        let ar = path.meta.accessRoles;
        let canAccess = true;
        if (path.matched && path.matched.length && path.matched[1] && path.matched[1].meta.accessRoles) { // проверка доступа к роутам модуля
            ar = path.matched[1].meta.accessRoles;
        }
        if (ar) {
            if (typeof ar === 'function') {
                if (!ar(app)) {
                    canAccess = false;
                }
            } else if (!app.$checkPermission(ar)) {
                canAccess = false;
            }
        }
        return canAccess;
    };

    try {
        if (!checkAccess(appRouter.app.$route)) {
            appRouter.push('/forbidden');
        }
    } catch (e) {
        console.warn(e);
    }

    // app.$mount('#app');

    appRouter.beforeResolve((to, from, next) => {
        if (!checkAccess(to)) {
            console.warn('Access denied');
            app.$message.error('Доступ запрещен');
            appRouter.push('/forbidden');
            return false;
        }
        next();
    });
    appRouter.beforeEach((to, from, next) => { //
        // if (node_env !== 'development') checkAppUpdate(); "Проверка хэша приложения"?? не работает
        if (sessionStorage.getItem('backUrl')) sessionStorage.removeItem('backUrl');
        if (from.params.queryPath) {
            to.params.backUrl = from.params.queryPath;
            sessionStorage.setItem('backUrl', JSON.stringify({
                value: from.params.queryPath,
                fullPath: to.fullPath,
            }));
        } else if (!from.params.backHistory && from.matched.length > 1 && to.matched.length > 1 && from.matched[1].path !== to.matched[1].path) {
            to.params.backHistory = true;
        }
        // *
        if (to.meta.routerRole === 'root') {
            sessionStorage.removeItem('routerItems');
        } else if (!from.params.goBack && from.meta.routerRole) {
            const routerItems = sessionStorage.getItem('routerItems') ? JSON.parse(sessionStorage.getItem('routerItems')) : [];
            routerItems.push(from.params.queryPath || from.fullPath);
            sessionStorage.setItem('routerItems', JSON.stringify(routerItems));
        }
        if (!checkAccess(to)) {
            console.warn('Access denied');
            app.$message.error(app.$t('forbidden.403_type'));
            return false;
        }
        if (localStorage.getItem('token') && localStorage.getItem('refresh_token')) {
            store.dispatch(REFRESH, {
                token: localStorage.getItem('token'),
                refresh_token: localStorage.getItem('refresh_token'),
            });
        } else {
            store.dispatch(LOGOUT);
        }
        next();
    });

    const needUpdatePage = () => {
        const loading = app.$loading({ lock: true });

        setTimeout(() => {
            loading.close();
            app.$confirm(app.$t('messages.need_update_page'), app.$t('messages.need_update_page_title'), {
                confirmButtonText: app.$t('buttons.refresh'),
                cancelButtonText: app.$t('buttons.cancel'),
                type: 'warning',
            }).then(() => {
                window.location.reload();
            });
        }, 1500);
    };
    appRouter.onError((error) => {
        if (error.name === 'ChunkLoadError') {
            if (process.env.VUE_APP_ENV_DEV) console.log('router ChunkLoadError');
            needUpdatePage();
        }
    });
    Vue.config.warnHandler = (msg) => {
        if (/ChunkLoadError/.test(msg)) {
            if (process.env.VUE_APP_ENV_DEV) console.log('component ChunkLoadError');
            needUpdatePage();
        }
    };
    return app;
}).then((app) => {
    // store.dispatch('getAgglomerationList');
    app.$mount('#app');
});
