import store from '@/store/store';
import { USER_PERMISSIONS } from '@/store/auth/auth-getters';

export default {
    install(Vue, permissions) {
        Object.keys(permissions).forEach((key) => {
            Vue.prototype[key] = function () {
                return permissions[key](this);
            };
        });
    },
};

export const getPermission = () => {
    const { tokenParsed } = store.state;
    if (tokenParsed && tokenParsed.realm_access && tokenParsed.realm_access.roles) return tokenParsed.realm_access.roles;
    return [];
};

export const checkPermission = (role, operator = 'and', callback) => {
    // const { tokenParsed } = store.state;
    const userRoles = store.getters[USER_PERMISSIONS];
    if (!userRoles || !userRoles.length) return false;
    // if (!tokenParsed || !tokenParsed.realm_access || !tokenParsed.realm_access.roles) return false;
    // const userRoles = tokenParsed.realm_access.roles;
    let canAccess = true;
    operator = String(operator).toLowerCase();

    if (Array.isArray(role)) {
        canAccess = operator !== 'or';
        for (let i = 0; i < role.length; i += 1) {
            const curRole = role[i];
            if (operator === 'or') {
                if (userRoles.includes(curRole)) {
                    canAccess = true;
                    break;
                }
            } else if (!userRoles.includes(curRole)) {
                canAccess = false;
                return false;
            }
        }
    } else {
        canAccess = userRoles.includes(role);
    }
    if (canAccess && callback && typeof callback === 'function') {
        callback();
    }
    return canAccess;
};
