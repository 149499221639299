export default {
    methods: {
        copyObjUsingJson(obj) {
            try {
                return JSON.parse(JSON.stringify(obj));
            } catch (e) {
                return obj;
            }
        },
        hCleanEmptyFields(value) {
            if (!value || typeof value !== 'object') return {};
            const keys = Object.keys(value);
            const newObj = {};
            keys.forEach((key) => {
                if (value[key] !== null && value[key] !== '' && value[key] !== undefined) newObj[key] = value[key];
            });
            return newObj;
        },
        hFormatNumber(value, fixed = 2) {
            if (value === undefined || value === null || typeof value === 'string') return value;
            if (Number.isInteger(value)) return value;
            return Number.parseFloat(value).toFixed(fixed);
        },
        hHasValue(value) {
            return value || value === false || value === 0;
        },
        isEmpty(value) {
            if (value == null) return true;
            if (Array.isArray(value) || typeof value === 'string') return !value.length;
            if (typeof value === 'object') return !Object.keys(value).length;
            return true;
        },
        isEqual(a, b) {
            try {
                const aProps = Object.getOwnPropertyNames(a);
                const bProps = Object.getOwnPropertyNames(b);
                if (aProps.length !== bProps.length) return false;
                return aProps.every((prop) => a[prop] === b[prop]);
            } catch (e) {
                return false;
            }
        },
        isEqualArray(a, b) {
            try {
                if (a.length !== b.length) return false;
                return a.every((val) => b.indexOf(val) + 1);
            } catch (e) {
                return false;
            }
        },
    },
};
