/* eslint-disable */
import axios from 'axios';
import store from '@/store/store';
// import { dictionarys } from '../Plugin/Dictionary/dictionary-state';
// import { LOAD, UPDATE_LANG } from '../Plugin/Dictionary/dictionary-actions';
// import { API_DICTIONARY } from '../Plugin/Dictionary/dictionary-getters';

const baseURL = '/api'
const locale = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'ru';
let acceptLanguage = '';
switch (locale) {
    case 'ru':
        acceptLanguage = 'ru-RU,ru;q=0.8,en-US;q=0.5,en;q=0.3';
        break;
    case 'en':
        acceptLanguage = 'en-US,en;q=0.8,ru-RU;q=0.5,ru;q=0.3';
        break;
    default:
        acceptLanguage = 'ru-RU,ru;q=0.8,en-US;q=0.5,en;q=0.3';
}

const api = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Language': acceptLanguage,
    },
});

const apiFile = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/octet-stream',
    },
});

const local = axios.create({
    baseURL: '/',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

export function setToken(token, cb, refresh_token) {
    if (token) {
        // api.defaults.headers.common.Authorization = `Bearer ${token}`;
        api.defaults.headers.common.Authorization = `Bearer ${token}`;
        apiFile.defaults.headers.common.Authorization = `Bearer ${token}`;
        if (cb) {
            cb();
        }
    }
}
export function setCustomHeaders(params, cb) {
    if (params) {
        for (const key in params) {
            api.defaults.headers.common[key] = params[key];
        }
        if (cb) {
            cb();
        }
    }
}
export const login = (data) => api.post('/auth/login', data);
export const logout = () => api.get('/auth/logout');
export const refreshToken = (data) => api.post('/auth/refresh', data);
export const setPassword = (hash, data) => api.post(`/auth/set_password/${hash}`, data);
export const resetPassword = (data) => api.post(`/auth/reset_password`, data);
export const whoami = () => api.get(`/auth/who_am_i`);

export const getModules = () => local.get('modules.json');
export const getConfig = () => local.get('config.json');

export const getAdministrativeBorderList = (data) => api.post(`/dictionary/administrative_border/list`, data);

export const getWorkbenchEvents = (data) => api.post(`/its/road_events`, data);
export const getWorkbenchAvailableEvents = (data) => api.post(`/its/road_events/list/available`, data);
export const processWorkbenchEventById = (id) => api.post(`/its/road_events/${id}/close`);
export const getWorkbenchEventCreateRequest = (id) => api.post(`/its/road_events/${id}/create_request`);
export const getWorkbenchWorkRequests = (data) => api.post(`/its/work_requests/list/available`, data);

export const getAgglomerationList = () => api.post(`dictionary/agglomeration_types/list`);

export const AuthApi = {
    login,
    logout,
    refreshToken,
    setPassword,
    resetPassword,
    whoami,
}
