export default {
    $accessCargoTransportation: (ctx) => ctx.$checkPermission([
        'eputs_cargo_transportation_view',
        'eputs_cargo_transportation_full_access',
    ], 'or'),
    $accessEco: (ctx) => ctx.$checkPermission([
        'eputs_eco_view',
    ]),
    $accessEvents: (ctx) => ctx.$checkPermission([
        'eputs_object_event_full_access',
        'eputs_object_event_view',
        'eputs_events_full_access',
        'eputs_events_view',
    ], 'or'),
    $accessEcoIndicators: (ctx) => ctx.$checkPermission([
        'eputs_eco_indicators',
    ]),
    $accessExternalEmergency: (ctx) => ctx.$checkPermission([
        'eputs_external_emergency',
    ]),
    $accessExternalEvents: (ctx) => ctx.$checkPermission([
        'eputs_external_events',
    ]),
    $accessGeofencesRoadWorks: (ctx) => ctx.$checkPermission([
        'eputs_geofences_road_works_view',
    ]),
    $accessGeoobjectsSearch: (ctx) => ctx.$checkPermission([
        'eputs_geoobjects_search',
    ]),
    $accessMeteo: (ctx) => ctx.$checkPermission([
        'eputs_meteo_view',
    ]),
    $accessObjects: (ctx) => ctx.$checkPermission([
        'eputs_object_event_full_access',
        'eputs_object_event_view',
        'eputs_objects_full_access',
        'eputs_objects_view',
    ], 'or'),
    $accessParkings: (ctx) => ctx.$checkPermission([
        'eputs_parkings_view',
    ]),
    $accessPassengerTraffic: (ctx) => ctx.$checkPermission([
        'eputs_passenger_traffic',
    ]),
    $accessPublicTransport: (ctx) => ctx.$checkPermission([
        'eputs_public_transport_view',
    ]),
    $accessPublicTransportInfrastructure: (ctx) => ctx.$checkPermission([
        'eputs_public_transport_infrastructure_view',
        'eputs_public_transport_infrastructure_full_access',
    ], 'or'),
    $accessRoadAccident: (ctx) => ctx.$checkPermission([
        'eputs_road_accident_full_access',
        'eputs_road_accident_view',
    ], 'or'),
    $accessRoadAccidentPublicTransport: (ctx) => ctx.$checkPermission([
        'eputs_road_accident_involving_public_transport_full_access',
        'eputs_road_accident_involving_public_transport_view',
    ], 'or'),
    $accessRoadVehicle: (ctx) => ctx.$checkPermission([
        'eputs_road_works',
        'eputs_road_vehicle_view',
        'eputs_road_vehicle_full_access',
    ], 'or'),
    $accessRoadVehicleIndicators: (ctx) => ctx.$checkPermission([
        'eputs_road_vehicle_indicators',
    ]),
    $accessRoadWorksIndicators: (ctx) => ctx.$checkPermission([
        'eputs_road_works_indicators',
    ]),
    $accessRoadWorksView: (ctx) => ctx.$checkPermission([
        'eputs_road_works_view',
    ]),
    $accessTasks: (ctx) => ctx.$checkPermission([
        'eputs_tasks_view',
        'eputs_tasks_customer',
        'eputs_tasks_assign_executor',
        'eputs_tasks_executor',
    ], 'or'),
    $accessTrafficDetector: (ctx) => ctx.$checkPermission([
        'eputs_traffic_detector_view',
    ]),
    $accessTransportation: (ctx) => ctx.$checkPermission([
        'eputs_application_full_access',
        'eputs_application_view',
        'eputs_transport_infrastructure_view',
        'eputs_transport_infrastructure_full_access',
        'eputs_public_transport_infrastructure_full_access',
        'eputs_public_transport_infrastructure_view',
    ], 'or'),
    $accessTransportInfrastructure: (ctx) => ctx.$checkPermission([
        'eputs_transport_infrastructure_view',
        'eputs_transport_infrastructure_full_access',
    ], 'or'),
    $accessTransportInfrastructureFull: (ctx) => ctx.$checkPermission([
        'eputs_transport_infrastructure_full_access',
    ], 'or'),
    $accessVehicleCarryingChildren: (ctx) => ctx.$checkPermission([
        'eputs_vehicle_carrying_children_full_access',
        'eputs_vehicle_carrying_children_view',
    ], 'or'),
};
