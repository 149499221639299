export default (nStrOut, shot, length = 2) => {
    if (!nStrOut) return '0,00';
    let nStr = Math.round(nStrOut * 100) / 100;
    nStr = `${nStr.toFixed(length)}`;
    const x = nStr.split('.');
    let x1 = x[0];
    const x2 = x.length > 1 && !shot ? `,${x[1]}` : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1 $2');
    }
    return x1 + x2;
};
