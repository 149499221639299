/* eslint-disable */
import jwtDecode from 'jwt-decode';
import { AuthApi, setToken } from '@/api';

import {
    LOGIN_SUCCESS_MUTATION,
    LOGOUT_MUTATION,
    REFRESH_TIMER_MUTATION,
    WHO_A_MI as WHO_A_MI_MUTATION,
} from './auth-mutations';

export const LOGIN = 'fast/auth/actions/LOGIN';
export const LOGOUT = 'fast/auth/actions/LOGOUT';
export const REFRESH = 'fast/auth/actions/REFRESH';
export const REFRESH_TIMER = 'fast/auth/actions/REFRESH_TIMER';
// export const LOGIN_SUCCESS = 'fast/auth/actions/LOGIN_SUCCESS';
// export const LOGIN_SUCCESS_REDIRECT = 'fast/auth/actions/LOGIN_SUCCESS_REDIRECT';
export const WHO_A_MI = 'fast/auth/actions/WHO_A_MI';

function setCookie(c_name, value, exdays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
    document.cookie = c_name + "=" + c_value + "; path=/";
}

function getCookie(c_name) {
    var i, x, y, ARRcookies = document.cookie.split(";");
    for (i = 0; i < ARRcookies.length; i++) {
        x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
        y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
        x = x.replace(/^\s+|\s+$/g, "");
        if (x == c_name) {
            return unescape(y);
        }
    }
}

export default {
    [LOGIN]({ dispatch, state, commit }, altAuth) {
        const locationPathname = window.location.pathname;
        if (altAuth) {
            setToken(altAuth.access_token, null, altAuth.refresh_token);
            // window.www = this;

            // commit(LOGIN_SUCCESS_MUTATION, {
            //     user: jwtDecode(altAuth.access_token),
            //     token: altAuth.token,
            //     refreshToken: altAuth.refreshToken,
            // });
            dispatch(WHO_A_MI);
            dispatch(REFRESH_TIMER, {
                token: altAuth.access_token,
                refresh_token: altAuth.refresh_token,
                expires_in: altAuth.expires_in,
            });
        }
        if (
            !locationPathname ||
            (locationPathname === '/login' && (altAuth || !localStorage.getItem('token'))) ||
            (locationPathname === '/registration' && (altAuth || !localStorage.getItem('token'))) ||
            locationPathname.indexOf('email_confirmation') >= 0 ||
            locationPathname.indexOf('set_password') >= 0 ||
            locationPathname.indexOf('registration_confirmation') >= 0
        ) {
            commit('setActiveState', true);
            return false;
        }

        let resolveLogin;
        let promiseLogin = new Promise((resolve) => {
            resolveLogin = resolve;
        });

        if (!localStorage.getItem('refresh_token') && getCookie('refresh_token')) {
            localStorage.setItem('refresh_token', getCookie('refresh_token'));
        }

        if (!localStorage.getItem('refresh_token')) {
            // User not authorized
            window.location.href = `${window.location.origin}/login`;
        } else {
            // Try to refresh
            dispatch(REFRESH, {
                // token: localStorage.getItem('token'),
                refresh_token: localStorage.getItem('refresh_token')
            }).then(() => {
                dispatch(WHO_A_MI);
                resolveLogin();
            });

        }
        return promiseLogin;
    },
    async [WHO_A_MI]({ commit, dispatch }) {
        let data = null;
        try {
            const whoAmiRaw = await AuthApi.whoami();
            data = whoAmiRaw?.data;
            commit(WHO_A_MI_MUTATION, whoAmiRaw);
            dispatch('getAgglomerationList');
        } catch (e) {
            const whoAmiRaw = {};
            commit(WHO_A_MI_MUTATION, whoAmiRaw);
        } finally {
            dispatch('checkMinisterAccess', data);
        }
    },
    [REFRESH]({ dispatch, state, commit }, data) {
        const { token, refresh_token } = data;
        // setToken(token, null, refresh_token);
        if (state.refreshTimer) {
            clearTimeout(state.refreshTimer);
            commit(REFRESH_TIMER_MUTATION, null);
        }
        return AuthApi.refreshToken({ refresh_token }).then((answer) => {
            setToken(answer.data.access_token, null, answer.data.refresh_token);

            const tokenParsed = jwtDecode(answer.data.access_token);
            localStorage.setItem('token', answer.data.access_token);
            localStorage.setItem('refresh_token', answer.data.refresh_token);
            localStorage.setItem('tokenParsed', JSON.stringify(tokenParsed));

            setCookie('refresh_token', answer.data.refresh_token, 1);

            commit(LOGIN_SUCCESS_MUTATION, {
                // user: tokenParsed,
                token: answer.data.access_token,
                refreshToken: answer.data.refresh_token,
            });
            commit('tokenParsed', tokenParsed);
            dispatch(REFRESH_TIMER, {
                token: answer.data.access_token,
                refresh_token: answer.data.refresh_token,
                expires_in: answer.data.expires_in,
            });
        }).catch(err => {
            dispatch(LOGOUT);
        })
    },
    [REFRESH_TIMER]({ dispatch, state, commit }, data) {
        if (data.expires_in) {
            const timer = setTimeout(() => {
                dispatch(LOGOUT);
            }, data.expires_in * 1000);
            commit(REFRESH_TIMER_MUTATION, timer);
        }
    },
    // [LOGIN_SUCCESS]({ commit }) {
    //     setToken(auth.token);
    //     commit(LOGIN_SUCCESS_MUTATION, {
    //         user: auth.tokenParsed,
    //         token: auth.token,
    //         refreshToken: auth.refreshToken,
    //     });
    // },
    // [LOGIN_SUCCESS_REDIRECT]({ commit }) {
    //     setToken(auth.token);
    //     commit(LOGIN_SUCCESS_MUTATION, {
    //         user: auth.tokenParsed,
    //         token: auth.token,
    //         refreshToken: auth.refreshToken,
    //     });
    //     if (['/login', '/registration'].includes(window.location.pathname)) {
    //         window.location.href = '/main';
    //     }
    // },
    [LOGOUT]({ commit }) {
        setCookie('X-Authorization-Token', "", {
            expires: -1
        });
        setCookie('JSESSIONID', "", {
            expires: -1
        });
        setCookie('refresh_token', '', {
            expires: -1
        });
        commit(LOGOUT_MUTATION);
        const redirectUri = `${window.location.origin}/login`;
        localStorage.removeItem('sopt-user');
        localStorage.removeItem('token');
        localStorage.removeItem('tokenParsed');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('expires_in');
        localStorage.removeItem('agglomeration');
        AuthApi.logout();
        window.location.href = redirectUri;
    },
};
