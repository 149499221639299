import moment from 'moment';
import EventBus from '@/eventBus';
import { v4 as uuidv4 } from 'uuid';

export default {
    data() {
        return {
            eventBusHandlers: {},
        };
    },
    computed: {
        apiUrl() {
            return '/api';
        },
    },
    beforeDestroy() {
        this.eventBusHandlersDisable();
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(value).format('DD.MM.YYYY');
            }
            return null;
        },
        formatHHMMSS(seconds) {
            const h = Math.floor(seconds / 3600);
            let m = Math.floor((seconds % 3600) / 60);
            let s = Math.round(seconds % 60);
            m = m < 10 ? `0${m}` : m;
            s = s < 10 ? `0${s}` : s;
            return `${h}:${m}:${s}`;
        },
        getGeometryCoordinates(object) {
            let value = [];
            Object.values(object).forEach((v) => {
                if (Array.isArray(v) && v.length === 2 && typeof v[0] === 'number' && typeof v[1] === 'number') {
                    value.push(v);
                } else
                if (typeof v === 'object') {
                    value = value.concat(this.getGeometryCoordinates(v));
                }
            });
            return value;
        },
        getImgUrl(fileName) {
            try {
                const images = require.context('@/assets/img/', true, /\.(png|jpe?g|svg)$/);
                return images(fileName);
            } catch (e) {
                console.error('getImgUrl', e);
                return null;
            }
        },
        eventBusHandlersDisable() {
            Object.keys(this.eventBusHandlers).forEach((id) => {
                EventBus.$off(this.eventBusHandlers[id].key, this.eventBusHandlers[id].handler);
            });
        },
        eventBusOn(key, handler) {
            const id = `${key}-${uuidv4()}`;
            this.eventBusHandlers[id] = {
                key,
                handler,
            };
            EventBus.$on(this.eventBusHandlers[id].key, this.eventBusHandlers[id].handler);
        },
        loadMarkerImage(name, image, size = [70, 70]) {
            try {
                if (this.map.hasImage(name)) return;
                const img = new Image(size[0], size[1]);
                img.onload = () => {
                    if (!this.map.hasImage(name)) {
                        this.map.addImage(name, img);
                    }
                };
                img.src = image;
            } catch (e) {
                console.error(e);
            }
        },
        pluralize(number, words) {
            const cases = [2, 0, 1, 1, 1, 2];
            return words[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
        },
        validateCoordinates(coords) {
            let coordinates = [];
            if (coords && coords !== 'null') {
                if (Array.isArray(coords)) {
                    coordinates = coords;
                }
                if (typeof coords === 'string') {
                    coordinates = coords.match(/\((.*?)\)/)[1].split(' ');
                }
            }
            return coordinates;
        },
    },
};
