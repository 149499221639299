/* eslint-disable */
import { navigateToUrl } from 'single-spa';
import Vue from 'vue';
import Vuex from 'vuex';
import turfBbox from '@turf/bbox';
import auth from '@/store/auth/auth-module';

import Kazan from '../assets/files/Kazan_agglomeration.json';
import Naberezhnye from '../assets/files/Naberezhnye_chelny_agglomeration.json';
import {
    getAdministrativeBorderList,
    getConfig,
    getAgglomerationList,
} from '@/api';

Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        config: {
            extends: [],
        },
        tokenParsed: null,
        userPermissions: null,
        moduleId: null,
        modulesLinks: {},
        mapStylesList: [
            {
                url: '/mapStyles/dark/style.json',
                font: [
                    "DIN Pro Medium",
                    "Arial Unicode MS Regular"
                ],
                title: 'Темный',
                key: 'dark',
            },
            {
                url: '/mapStyles/light/style.json',
                font: [
                    "DIN Pro Medium",
                    "Arial Unicode MS Regular"
                ],
                // url: '/white-positron-russia.json',
                // font: ['Noto Sans Regular'],
                title: 'Серый',
                key: 'default',
            },
            {
                url: '/color-style.json',
                font: ['Noto Sans Regular'],
                title: 'Цветной',
                key: 'color',
            },
        ],
        mapStylesCurrent: 'dark',
        // mapToken: 'pk.eyJ1IjoiZGltYWNoaWNoIiwiYSI6ImNpbmx3Z2JwaTAwYWx2eG0yanB1dXpvcXgifQ.Xw3J7hdGkvAzGDe_5vhdAw',
        mapToken: 'pk.eyJ1Ijoic3lzdGx5c3QiLCJhIjoiY2t2NmkzNWZxMjR5ZzJvbHB2aXh1dTVteCJ9.5ZQEloFjna1uUNd2sN14wQ',
        mapMaxBounds: [
            15, 40,
            180, 82,
        ],
        mapZoom: 3,
        mapCenter: [38.97603, 45.04484],
        workbenchNotifyVisible: false,
        workbenchRoadEventsCount: 0,
        workbenchWorkRequestsCount: 0,
        mapWidgetsData: {},
        mapGraphSearchTag: '',
        mapGraphEditMode: false,
        agglomirationName: '',
        administrative_level: 4,
        agglomerationList: [],
        selectedAgglomeration: null,
        administrativeObjectData: null,
        agglomerationZone: null,
        activeState: false,
        theme: 'dark',
    },
    getters: {
        moduleId: (state) => state.moduleId,
        mapToken: (state) => state.mapToken,
        workbenchNotifyVisible: (state) => state.workbenchNotifyVisible,
        mapStyles: (state) => (state.mapStylesList.find((item) => item.key === state.mapStylesCurrent) || state.mapStylesList[0]).url,
        mapFonts: (state) => (state.mapStylesList.find((item) => item.key === state.mapStylesCurrent) || state.mapStylesList[0]).font,
        mapGraphEditMode: (state) => state.mapGraphEditMode,
        mapGraphSearchTag: (state) => state.mapGraphSearchTag,
        mapMaxBounds: (state) => state.mapMaxBounds,
        agglomirationName: (state) => state.agglomirationName,
        administrativeLevel: (state) => state.administrativeLevel,
        getConfig: (state) => state.config,
        configExtends: (state) => (state.config && state.config.extends) ? state.config.extends : [],
        agglomerationList: (state) => {
            if (state.auth.user && state.auth.user.attributes && state.auth.user.attributes.agglomeration_types) {
                return state.agglomerationList.filter(({ code }) => state.auth.user.attributes.agglomeration_types.includes(code));
            } else {
                return [];
            }
        },
        mapZoom: (state) => state.mapZoom,
        mapCenter: (state) => state.mapCenter,
        selectedAgglomeration: (state) => state.selectedAgglomeration,
        administrativeObjectData: (state) => state.administrativeObjectData,
        agglomerationZone: (state) => state.agglomerationZone,
        activeState: (state) => state.activeState,
        theme: (state) => state.theme,
    },
    mutations: {
        setWorkbenchNotifyVisible(state, isVisible) {
            state.workbenchNotifyVisible = isVisible;
        },
        tokenParsed(state, data) {
            state.tokenParsed = data;
        },
        setConfig(state, payload) {
            state.config = payload;
        },
        setModuleId(state, id) {
            state.moduleId = id;
        },
        setModulesLinks(state, that) {
            if (that.isEmpty(state.modulesLinks)) {
                const routesObj = {};
                that.$router.options?.routes?.reduce((acc, route) => acc.concat(route.children), []).forEach((route) => {
                    if (route && route.props && route.props.moduleId !== undefined) {
                        let access = null;
                        route.props.menu.forEach((item) => {
                            if (!access) {
                                if ((item.accessRoles && item.accessRoles(that)) || (!item.accessRoles && !item.subMenu)) {
                                    access = item.href;
                                }
                                if (item.subMenu) {
                                    item.subMenu.forEach((item2) => {
                                        if (!access) {
                                            if ((item2.accessRoles && item2.accessRoles(that)) || !item2.accessRoles) {
                                                access = item2.href;
                                            }
                                        }
                                    });
                                }
                            }
                        });
                        if (route.meta && route.meta.accessRoles && !route.meta.accessRoles(that)) { // проверка доступа к модулю, disable меню

                        } else if (access) {
                            routesObj[route.props.moduleId] = access;
                        }
                    }
                });
                state.modulesLinks = routesObj;
            }
        },
        setMapWidgetsData(state, obj) {
            state.mapWidgetsData[obj.key] = {
                timestamp: obj.timestamp,
                data: obj.data,
            };
        },
        setMapStyles(state, value) {
            state.mapStylesCurrent = value;
            localStorage.mapStylesCurrent = value;
        },
        setGraphEditMode(state, value) {
            state.mapGraphEditMode = value;
        },
        setGraphSearchTag(state, value) {
            state.mapGraphSearchTag = value;
        },
        setAgglomirationName(state, payload) {
            state.agglomirationName = payload;
        },
        setAdministrativeBorder(state, payload) {
            state.mapMaxBounds = payload;
        },
        setAgglomerationList(state, payload) {
            state.agglomerationList = payload;
        },
        setAgglomeration(state, payload) {
            state.selectedAgglomeration = payload;
        },
        setMapZoom(state, payload) {
            state.mapZoom = payload;
        },
        setMapCenter(state, payload) {
            state.mapCenter = payload;
        },
        setMapAgglomerationZone(state, payload) {
            state.agglomerationZone = payload;
        },
        setActiveState(state, payload) {
            state.activeState = payload;
        },
        setAdministrativeObjectData(state, payload) {
            state.administrativeObjectData = payload;
        },
        setTheme(state, payload) {
            state.theme = payload;
            localStorage.setItem('theme', payload);
            document.body.setAttribute('data-theme', payload);
        }
    },
    actions: {
        fetchConfig({ commit }) {
            try {
                return getConfig().then(({ data = {}}) => {
                    commit('setConfig', data);
                });
            } catch (e) {
                console.error(e);
                return {};
            }
        },
        async getAdministrativeBorder({ commit, state, dispatch }, code) {
            try {
                const selectedAgglomeration = state.selectedAgglomeration || code;
                const { data: { data }} = await getAdministrativeBorderList({
                    filter: [
                        {
                            value: selectedAgglomeration,
                            property: 'agglomeration_type_code',
                            operator: 'eq',
                        },
                    ],
                });

                dispatch('setAgglomerationBorder', data);
            } catch (e) {
                console.error(e);
            }
        },
        setAgglomerationBorder({ commit, state }, data) {
            if (data && data[0] && data[0].location) {
                commit('setAgglomirationName', data[0].name);
                const bbox = turfBbox(data[0].location);
                commit('setAdministrativeBorder', bbox);
                commit('setAdministrativeObjectData', data[0]);

                if (data[0].administrative_level === 5) {
                    commit('setMapAgglomerationZone', data[0].location);
                } else {
                    commit('setMapAgglomerationZone', {
                        features: [],
                        type: 'FeatureCollection',
                    });
                }
            }
        },
        async getAgglomerationList({ commit, state, dispatch }) {
            const userAgglomeration = localStorage.getItem('agglomeration') || '';

            try {
                const { data: { data }} = await getAgglomerationList();
                commit('setAgglomerationList', data);
                if (state.auth.user && state.auth.user.attributes && state.auth.user.attributes) {
                    if (!state.auth.user.attributes.agglomeration_types || (state.auth.user.attributes.agglomeration_types && state.auth.user.attributes.agglomeration_types.length === 0)) {
                        const { data: { data } } = await getAdministrativeBorderList({
                            filter: {
                                property: 'administrative_level',
                                value: 4,
                                operator: 'eq',
                            },
                        });
                        commit('setAgglomeration');
                        dispatch('setAgglomerationBorder', data);
                        return;
                    }

                    if (userAgglomeration) {
                        dispatch('setAgglomeration', userAgglomeration);
                        return;
                    }

                    if (state.auth.user.attributes.agglomeration_types && state.auth.user.attributes.agglomeration_types.includes('Republic_of_Tatarstan')) {
                        dispatch('setAgglomeration', 'Republic_of_Tatarstan');
                        return;
                    }

                    if (state.auth.user.attributes.agglomeration_types && state.auth.user.attributes.agglomeration_types.includes('Kazan_agglomeration')) {
                        dispatch('setAgglomeration', 'Kazan_agglomeration');
                        return;
                    }

                    if (state.auth.user.attributes.agglomeration_types && state.auth.user.attributes.agglomeration_types.includes('Naberezhnye_chelny_agglomeration')) {
                        dispatch('setAgglomeration', 'Naberezhnye_chelny_agglomeration');
                        return;
                    }
                    if (state.auth.user.attributes.agglomeration_types && state.auth.user.attributes.agglomeration_types && state.auth.user.attributes.agglomeration_types) {
                        dispatch('setAgglomeration', state.auth.user.attributes.agglomeration_types[0]);
                        return;
                    }
                }
            } catch (e) {
                console.error(e);
            } finally {
                commit('setActiveState', true);
            }
        },
        setAgglomeration({ commit, dispatch }, code) {
            localStorage.setItem('agglomeration', code);
            commit('setAgglomeration', code);
            dispatch('getAdministrativeBorder');
            if (code === 'Kazan_agglomeration') {
                commit('setMapZoom', 9);
                commit('setMapCenter', [49.294117, 55.891343]);
            }
            if (code === 'Naberezhnye_chelny_agglomeration') {
                commit('setMapZoom', 9);
                commit('setMapCenter', [52.577107, 55.745142]);
            }
            if (code === 'Republic_of_Tatarstan') {
                commit('setMapZoom', 8);
                commit('setMapCenter', [50.48538723661636, 55.43426538994433]);
            }
        },
        checkMinisterAccess({ state }, userData) {
            if (userData?.permissions?.length && userData.permissions.indexOf('eputs_minister_access') > -1) {
                navigateToUrl('/module/minister/map');
            }
        },
        setTheme({ commit }, code) {
            commit('setTheme', code);
            localStorage.setItem('theme', code);
        },
        setMapStyles({ commit }, code) {
            commit('setMapStyles', code);
        },
    },
    modules: {
        auth,
    },
});
