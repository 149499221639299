export const LOGIN_SUCCESS_MUTATION = 'fast/auth/mutations/LOGIN_SUCCESS_MUTATION';
export const REFRESH_TIMER_MUTATION = 'fast/auth/mutations/REFRESH_TIMER_MUTATION';
export const LOGOUT_MUTATION = 'fast/auth/mutations/LOGOUT';
export const WHO_A_MI = 'fast/auth/mutations/WHO_A_MI';

export default {
    /* eslint-disable no-param-reassign */
    [LOGIN_SUCCESS_MUTATION](state, { user, token, refreshToken }) {
        // state.user = {
        //     ...state.user,
        //     ...user,
        // };
        state.token = token;
        state.refreshToken = refreshToken;
    },
    [LOGOUT_MUTATION](state) {
        state.user = null;
        state.token = null;
        state.refreshToken = null;
    },
    [REFRESH_TIMER_MUTATION](state, timer) {
        state.refreshTimer = timer;
    },
    [WHO_A_MI](state, { data }) {
        state.user = {
            ...state.user,
            ...data,
        };
        if (state.user && state.user.attributes && state.user.attributes.organization_id) {
            state.user.attributes.organization_id = Number(state.user.attributes.organization_id);
        }
        if (state.user && state.user.attributes && state.user.attributes && !state.user.attributes.agglomeration_type_codes) {
            // state.user.attributes.agglomeration_types = ['Republic_of_Tatarstan'];
        } else {
            state.user.attributes.agglomeration_types = state.user.attributes.agglomeration_type_codes;
        }
    },
};
