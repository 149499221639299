/* eslint-disable no-useless-escape */
/* eslint-disable no-control-regex */
import emailMask from 'text-mask-addons/dist/emailMask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export default {
    email: {
        mask: emailMask,
        guide: true,
    },
    phone: {
        mask: createNumberMask({
            prefix: '+',
            includeThousandsSeparator: false,
            allowDecimal: false,
            integerLimit: 15,
            allowLeadingZeroes: true,
        }),
        guide: false,
    },
    innerPhone: {
        mask: [
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
        ],
        guide: false,
    },
    phoneRF: {
        mask: [
            '+',
            '7',
            '-',
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
        ],
        guide: true,
    },
    ip: {
        mask: [
            /\d/,
            /\d/,
            /\d/,
            '.',
            /\d/,
            /\d/,
            /\d/,
            '.',
            /\d/,
            /\d/,
            /\d/,
            '.',
            /\d/,
            /\d/,
            /\d/,
        ],
        guide: true,
    },
    lat: {
        mask: createNumberMask({
            prefix: false,
            decimalLimit: 6,
            integerLimit: 2,
            requireDecimal: true,
            allowNegative: true,
            allowLeadingZeroes: true,
        }),
        guide: false,
    },
    long: {
        mask: createNumberMask({
            prefix: false,
            decimalLimit: 6,
            integerLimit: 3,
            requireDecimal: true,
            allowNegative: true,
            allowLeadingZeroes: true,
        }),
        guide: false,
    },
    documentNumberRF: {
        mask: [
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
        ],
        guide: false,
    },
    documentIssuerCodeRF: {
        mask: [
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
        ],
        guide: false,
    },
    passportRF: {
        mask: [
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
        ],
        guide: true,
    },
    passportSNG: {
        mask: [
            /[A-я]|[A-z]/,
            /[A-я]|[A-z]/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
        ],
        guide: true,
    },
    passportUA: {
        mask: [
            /[A-я]|[A-z]/,
            /[A-я]|[A-z]/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
        ],
        guide: true,
    },
    number: {
        number: true,
        mask: [...new Array(100)].map(() => /\d/),
        guide: false,
    },
    numberAll: {
        mask: createNumberMask({
            prefix: false,
            allowDecimal: false,
            allowNegative: true,
            includeThousandsSeparator: false,
        }),
    },
    year: {
        mask: [
            /\d/,
            /\d/,
            /\d/,
            /\d/,
        ],
        guide: true,
    },
    date: {
        mask: [
            /\d/,
            /\d/,
            '.',
            /\d/,
            /\d/,
            '.',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
        ],
        guide: true,
    },
    noZeroNumber: {
        mask: createNumberMask({
            prefix: false,
            includeThousandsSeparator: false,
            allowDecimal: true,
        }),
        guide: false,
    },
    numberPos: {
        number: true,
        mask: [/[1-9]/, ...[...new Array(100)].map(() => /\d/)],
        guide: false,
    },
    numberDot: {
        mask: createNumberMask({
            prefix: false,
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: ' ',
            requireDecimal: true,
            allowDecimal: true,
            decimalLimit: 6,
        }),
        // placeholderChar: '0',
        // guide: true,
        guide: false,
    },
    numberDotMobile: {
        mask: createNumberMask({
            prefix: false,
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: ' ',
            requireDecimal: true,
            allowDecimal: true,
            decimalLimit: 6,
        }),
        // placeholderChar: '0',
        // guide: true,
        guide: false,
    },
    vat: {
        mask: createNumberMask({
            prefix: false,
            includeThousandsSeparator: false,
            allowDecimal: true,
            decimalLimit: 20,
        }),
        guide: false,
    },
    float: {
        mask: createNumberMask({
            prefix: false,
            includeThousandsSeparator: false,
            allowDecimal: true,
            decimalLimit: 20,
            allowNegative: true,
        }),
        guide: false,
    },
    floatPlus: {
        mask: createNumberMask({
            prefix: false,
            includeThousandsSeparator: false,
            allowDecimal: true,
            decimalLimit: 20,
        }),
        guide: false,
    },
    routeNumber: {
        mask: [...new Array(10)].map(
            () => /([A-я]|[A-z]|[0-9]|-|№|Ё|ё| )/,
        ),
        guide: false,
    },
    text: {
        mask: [...new Array(300)].map(
            () => /([^\u0000-\u0040\u005B-\u0060\u007B-\u00BF\u02B0-\u036F\u00D7\u00F7\u2000-\u2BFF])|\-|\s/,
        ),
        guide: false,
    },
    name: {
        mask: [...new Array(300)].map(
            () => /([A-я]|[A-z]|[0-9]|-|№|Ё|ё| )/,
        ),
        guide: false,
    },
    latintext: {
        mask: [...new Array(300)].map(
            () => /([A-Z]|[a-z])|\d/,
        ),
        guide: false,
    },
    latinNumber: {
        mask: [...new Array(300)].map(
            () => /([A-Z]|[a-z]|[0-9])|\d/,
        ),
        guide: false,
    },
    latinNumberWithSpecials: {
        mask: [...new Array(300)].map(
            () => /([A-Z]|[a-z]|[0-9]|-|@|_|\.| )|\d/,
        ),
        guide: false,
    },
    ipDns: {
        mask: [...new Array(300)].map(
            () => /([a-z]|[0-9]|\.|-|_|)|\d/,
        ),
        guide: false,
    },
    ru: {
        mask: [...new Array(300)].map(
            () => /([А-Я]|[а-я]|Ё|ё| )/,
        ),
        guide: false,
    },
    ruNumber: {
        mask: [...new Array(300)].map(
            () => /([А-Я]|[а-я]|Ё|ё|[0-9]|\.|-| )|\d/,
        ),
        guide: false,
    },
    priority: {
        number: true,
        mask: [...new Array(3)].map(() => /\d/),
        guide: false,
    },
    inputtag: {
        mask: [...new Array(300)].map(
            () => /[^{}]/,
        ),
        guide: false,
    },
    gost: {
        mask: [...new Array(300)].map(
            () => /([A-Z]|[a-z]|[А-Я]|[а-я]|[0-9]|\?|\!|\,|\.|\-|\+|\*|\/|\"| )|\d/,
        ),
        guide: false,
    },
    duration: {
        mask: [
            /\d/,
            /\d/,
            ':',
            /\d/,
            /\d/,
        ],
        guide: true,
    },
};
