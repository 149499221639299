// Example
// v-regexInput="/^[А-яA-z]+\-[А-яA-z]+$|^[А-яA-z]+$|^[А-яA-z]+\-$/g"
// v-regexInput="'positiveNumberDot'"
/* eslint-disable */
const regexInput = {
  getInput(el) {
    let element = el;
    if (el.tagName.toLocaleUpperCase() !== 'INPUT') {
      const els = el.getElementsByTagName('input');
      if (els.length !== 1) {
        throw new Error(`regexInput requires 1 input, found ${els.length}`);
      } else {
        element = els[0];
      }
    }
    return element;
  },
  dispatch(el) {
    el.dispatchEvent(new Event('input'));
  },
  template: {
    positiveNumberDot: /(^\d+\.?\d+$)|(^\d+\.$)|^\d+$/g, // 123 || 123.123
    numberDot: /^-?$|(^-?\d+$)|(^-?\d+\.$)|(^-?\d+\.?\d+$)|(^\d+$)/g, // -123 || -123.123 || 123 || 123.123
    positiveNumber: /^\d+$/g, // 123
    positiveNumberTwoDigit: /((^|\s)[1-9]\d?(\s|$))|^(?:)$/g, // от 1 до 99 || пустое значение
    number: /^-?$|(^-?\d+$)|(^\d+$)/g, // -123 || 123
    twoWord: /^[А-яёЁA-z]+-[А-яёЁA-z]+$|^[А-яёЁA-z]+$|^[А-яёЁA-z]+-$/g, // Иванов-Петров || Иванов || One-Two || One
    fio: /^[А-яёЁ\- ]+$/g,
  },
};
export default {
  update(el, binding, vnode, oldVnode) {
    const element = regexInput.getInput(el);
    const newVal = `${vnode.data.model.value || ''}`;
    const oldVal = `${oldVnode.data.model.value || ''}`;
    let regexp;
    if (typeof binding.value === 'object') {
      regexp = binding.value;
    } else if (typeof binding.value === 'string') {
      regexp = regexInput.template[binding.value];
    }
    if (newVal !== undefined && oldVal !== undefined) {
      const a = newVal.match(regexp);
      const b = regexp.test(newVal);
      // const c = oldVal.match(regexp);
      // const d = regexp.test(oldVal);

      // if (!a && !b && c && d) {
      if (!a && !b) {
        if (element.value) {
          element.value = oldVal;
        } else {
          element.value = '';
        }
        regexInput.dispatch(element);
      }
    }
  },
};
