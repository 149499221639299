import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';

export default (date, mode) => {
    try {
        if (date) {
            const jsDate = new Date(date);
            let formatDate = 'DD.MM.YYYY, HH:mm:ss';
            if (mode === 'pretty') {
                formatDate = 'DD.MM.YYYY HH:mm';
            } else if (mode) {
                formatDate = mode;
            }
            return format(jsDate, formatDate, { locale: ru });
        }
        return '';
    } catch (e) {
        return date;
    }
};
