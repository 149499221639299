import i18n from '@/Plugin/Localisation/i18n';
import moment from 'moment';
/* eslint-disable */

export default {
    data() {
        const formHelperRuleName = this.$props && this.$props.hasOwnProperty('rules') ? 'rulesHelper' : 'rules';
        const formHelperLoadingName = this.$props && this.$props.hasOwnProperty('loading') ? 'loadingHelper' : 'loading';
        return {
            [formHelperRuleName]: {
                formValidationErrors: {},
                required: [
                    {
                        required: true,
                        trigger: 'change',
                        message: 'errors.required',
                        validator: (rule, value, callback) => {
                            if (value == null) {
                                callback(new Error(this.$t('errors.required')));
                            } else if (typeof value === 'string' && !value.replace(/ /g, '')) {
                                callback(new Error(this.$t('errors.required')));
                            } else if (Array.isArray(value) && (!value.length || (!value[0] && !Number.isInteger(value[0])))) {
                                callback(new Error(this.$t('errors.required')));
                            } else {
                                callback();
                            }
                        },
                    },
                ],
                requiredZero: [
                    {
                        required: true,
                        trigger: 'change',
                        message: 'errors.required',
                        validator: (rule, value, callback) => {
                            if (!value && value !== 0) {
                                callback(new Error(this.$t('errors.required')));
                            } else if (typeof value === 'string' && !value.replace(/ /g, '')) {
                                callback(new Error(this.$t('errors.required')));
                            } else if (Array.isArray(value) && (!value.length || !value[0])) {
                                callback(new Error(this.$t('errors.required')));
                            } else {
                                callback();
                            }
                        },
                    },
                ],
                requiredBoolean: [
                    {
                        required: true, type: 'boolean', message: 'errors.required', trigger: 'change',
                    },
                ],
                requiredDocument: [
                    {
                        required: true,
                        trigger: 'change',
                        validator: (rule, value, callback) => {
                            if (!value || !value.uuid) {
                                callback(new Error(this.$t('errors.document_required')));
                            } else {
                                callback();
                            }
                        },
                    },
                ],
                documentNumberRF: [
                    { required: true, message: 'errors.required', trigger: 'change' },
                    {
                        trigger: 'change',
                        validator: (rule, value, callback) => {
                            if (value === '') {
                                callback(new Error(this.$t('errors.required')));
                            } else if (value.length !== 11) {
                                callback(new Error('Серия и номер паспорта  должны состоять из 10 цифр'));
                            } else {
                                callback();
                            }
                        },
                    },
                ],
                documentIssuerCodeRF: [
                    { required: true, message: 'errors.required', trigger: 'change' },
                    {
                        trigger: 'change',
                        validator: (rule, value, callback) => {
                            if (value === '') {
                                callback(new Error(this.$t('errors.required')));
                            } else if (value.length !== 7) {
                                callback(new Error('Код подразделения должен состоять из 6 цифр'));
                            } else {
                                callback();
                            }
                        },
                    },
                ],
                email: [
                    { required: true, message: 'errors.required', trigger: 'change' },
                    {
                        trigger: 'change',
                        validator: (rule, value, callback) => {
                            const r = new RegExp(this.mailRegExp);
                            if (!value.length || !r.test(value)) {
                                callback(new Error(this.$t('errors.incorrect_email')));
                            } else {
                                callback();
                            }
                        },
                    },
                ],
                date: [
                    { required: true, message: 'errors.required', trigger: 'change' },
                    {
                        trigger: 'change',
                        validator: (rule, value, callback) => {
                            if (!value.length || !moment(value, 'DD.MM.YYYY', true).isValid()) {
                                callback(new Error(this.$t('errors.incorrect_date')));
                            } else {
                                callback();
                            }
                        },
                    },
                ],
                year: [
                    { required: true, message: 'errors.required', trigger: 'change' },
                    {
                        trigger: 'change',
                        validator: (rule, value, callback) => {
                            const r = new RegExp(/^\d{4}$/);
                            if (!value.length || !r.test(value) || Number(value) > new Date().getFullYear()) {
                                callback(new Error(this.$t('errors.incorrect_year')));
                            } else {
                                callback();
                            }
                        },
                    },
                ],
                noZero: [
                    { required: true, message: 'errors.required', trigger: 'change' },
                    {
                        trigger: 'change',
                        validator: (rule, value, callback) => {
                            if (Number(value) === 0) {
                                callback(new Error(this.$t('errors.no_zero')));
                            } else {
                                callback();
                            }
                        },
                    },
                ],
                authEmail: [
                    { required: true, message: 'errors.required', trigger: 'change' },
                    {
                        trigger: 'change',
                        validator: (rule, value, callback) => {
                            const r = new RegExp(this.authMailRegex);
                            if (!value.length || !r.test(value)) {
                                callback(new Error(this.$t('errors.incorrect_email')));
                            } else {
                                callback();
                            }
                        },
                    },
                ],
                phones: [
                    { required: true, message: 'errors.required', trigger: 'change' },
                    {
                        trigger: 'change',
                        validator: (rule, value, callback) => {
                            if (!value.length || value[0] === '') {
                                callback(new Error(this.$t('errors.required')));
                            } else {
                                callback();
                            }
                        },
                    },
                ],
                inn: [
                    { required: true, message: 'errors.required', trigger: 'change' },
                    {
                        max: 12,
                        trigger: 'change',
                        validator: (rule, value, callback) => {
                            if (value && value.length !== 12) {
                                callback(new Error(this.$t('errors.max_length_inn', { max: '12' })));
                            } else {
                                callback();
                            }
                        },
                    },
                ],
                innUl: [
                    { required: true, message: 'errors.required', trigger: 'change' },
                    {
                        max: 10,
                        trigger: 'change',
                        validator: (rule, value, callback) => {
                            if (value && value.length !== 10) {
                                callback(new Error(this.$t('errors.max_length_inn', { max: '10' })));
                            } else {
                                callback();
                            }
                        },
                    },
                ],
                ip: [
                    {
                        max: 15,
                        trigger: 'change',
                        validator: (rule, value, callback) => {
                            if (value) value = value.replace(/[^\d.]/g, '');
                            if (value && value.length < 15) {
                                callback(new Error(this.$t('errors.incorrect_format')));
                            } else {
                                callback();
                            }
                        },
                    },
                ],
                ipDns: [
                    {
                        max: 50,
                        trigger: 'change',
                        validator: (rule, value, callback) => {
                            if (!value) {
                                callback();
                                return;
                            }
                            if (!/^[^.].*\..*[^.]$/g.test(value) || /\.\./g.test(value)) {
                                callback(new Error(this.$t('errors.incorrect_format')));
                                return;
                            }
                            if (/\.\d+$/g.test(value)) {
                                if (!/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/g.test(value)) {
                                    callback(new Error(this.$t('errors.incorrect_format')));
                                    return;
                                }
                            } else if (!/\.([A-я]|[A-z]|Ё|ё)+$/g.test(value)) {
                                callback(new Error(this.$t('errors.incorrect_format')));
                                return;
                            }
                            callback();
                        },
                    },
                ],
                minLength: [
                    { required: true, message: 'errors.required', trigger: 'change' },
                    {
                        min: 2,
                        trigger: 'change',
                        validator: async (rule, value, callback) => {
                            if (!value.length || value.trim().length < 2) {
                                callback(new Error(`${this.$t('errors.min_length')} 2`));
                            }
                        },
                    },
                ],
                lat: [{
                    trigger: 'change',
                    validator: async (rule, value, callback) => {
                        if (+value < -90 || +value > 90) {
                            callback(new Error(`${this.$t('base.from')} -90° ${this.$t('base.to')} 90°`));
                        }
                    },
                }],
                long: [{
                    trigger: 'change',
                    validator: async (rule, value, callback) => {
                        if (+value < -180 || +value > 180) {
                            callback(new Error(`${this.$t('base.from')} -180° ${this.$t('base.to')} 180°`));
                        }
                    },
                }],
            },
            [formHelperLoadingName]: false,
            requestDataDefault: {limit: 99999},    // Для запроса списков
            editCardFields: new Set(),
            editCardForm: null,
            fieldErrors: {
                'field.must.not.be.null': 'errors.required',
                'add.plot.have.intersections': 'objects.intersections_error',
                'add.launch.complex.have.intersections': 'objects.intersections_error',
                'serial.number.already.exists': 'device.serial_exists',
            },
            mailRegExp: i18n.locale === 'ru'
                ? /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([а-яА-Яa-zA-Z\-0-9]+\.)+[а-яА-Яa-zA-Z]{2,}))$/g
                : /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
            authMailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([а-яА-Яa-zA-Z\-0-9]+\.)+[а-яА-Яa-zA-Z]{2,}))$/g,
            fileUploadUrl: 'api/admin/files/upload_file'
        };
    },
    methods: {
        validateForm(formName = 'ruleForm', resolveFunc, rejectFunc) {
            this.loading = true;
            this.$refs[formName].validate((valid) => {
                this.loading = false;
                if (valid) {
                    if (typeof resolveFunc === 'function') resolveFunc('success!!!');
                    else if (typeof this.submitForm === 'function') this.submitForm('success!!!');
                    return;
                }
                this.$message.error(this.$t('errors.validation'));
                if (typeof rejectFunc === 'function') rejectFunc('error submit!!');
            });
        },
        isHasError(validation_result, isHasErrorHelper) {
            if (!validation_result) return;

            const errorMessages = [];

            // fields
            const fieldErrors = {};
            if (validation_result.fields) {
                const errorFields = [];
                for (const property in validation_result.fields) {
                    if (validation_result.fields.hasOwnProperty(property) && property !== 'policy' && property !== 'policy_offer') {
                        property_local = property.split(/(?=[A-Z])/).join('_').toLowerCase();
                        let property_local = typeof isHasErrorHelper === 'function' ? isHasErrorHelper(property_local) : property_local;
                        if (property_local) {
                            const errorText = validation_result.fields[property][0];
                            fieldErrors[property_local] = this.fieldErrors[errorText] ? this.$t(this.fieldErrors[errorText]) : errorText;
                            errorFields.push(property_local);
                            errorMessages.push(fieldErrors[property_local]);
                        }
                    }
                }

                if (errorFields.length) {
                    this.rules.formValidationErrors = fieldErrors;
                    this.$nextTick(() => {
                        if (this.$refs.ruleForm) this.$refs.ruleForm.validateField(errorFields);
                    });
                }
            }

            // common_errors
            if (validation_result.common_errors && validation_result.common_errors.length) {
                validation_result.common_errors.forEach(errorText => {
                    errorMessages.push(this.fieldErrors[errorText] ? this.$t(this.fieldErrors[errorText]) : errorText);
                });
            }

            if (errorMessages.length) {
                const strErrors = errorMessages.reduce((str, errorText) => `${str}<br/><br/>${errorText}`);
                this.$message.error({
                    dangerouslyUseHTMLString: true,
                    message: strErrors,
                });
                return true;
            }

            if (Object.keys(fieldErrors).length) {
                this.$message.error(this.$t('errors.validation'));
                return true;
            }

            // success
            if (!validation_result.success) throw new Error('nosuccess');

            return false;
        },
        addFieldRequired(field, elForm) {
            this.editCardForm = elForm;
            this.editCardFields.add(field);
            this.$emit('editCardFieldsUpdate');
        },
        handleResponse({ data, task_status: { status = 'ok', result } }, {
            onSuccess, onFail, successMessage, failMessage,
        }) {
            try {
                if (data.validation_result.success && status !== 'error') {
                    if (successMessage) this.$message.success(this.$t(successMessage));
                    if (typeof onSuccess === 'function') onSuccess({ data, task_status: { status } });
                    return;
                }
                if (data.validation_result.common_errors && data.validation_result.common_errors.length) {
                    this.showErrorMessage(data.validation_result.common_errors);
                } else if (this.isHasError(data.validation_result, this.isHasErrorHelper)) {
                    // this.rules.formValidationErrors = data.validation_result.fields;
                    // this.$message.error(this.$t('errors.validation'));
                } else if (failMessage) {
                    this.$message.info(this.$t(failMessage));
                } else {
                    console.warn(`Task status: (${status}) - ${result || ''}\n
                        Response status - ${data.validation_result.success}`);
                }
                if (typeof onFail === 'function') onFail({ data, task_status: { status } });
            } catch (e) {
                console.error(e);
                console.warn('Pesponse handle error:', { data, status });
            } finally {}
        },
        errorHandler(e, methodName = '', message) {
            console.error(`error ${methodName}:`, e);

            if (!(/.*(Network Error|Request failed).*/.test(e.message))) {
                if (message) this.$message.error(message);
                else this.$message.error(this.$t('errors.base'));
                return;
            }

            try {
                let textError = '';
                switch (e.response.status) {
                    case 401: textError = this.$t('errors.unavailable'); break;
                    case 403: textError = this.$t('forbidden.403_type'); break;
                    default: textError = this.$t('errors.base'); break;
                }
                if (message) this.$message.error(`${message} ${e.response.status} - ${textError}`);
                else this.$message.error(`${this.$t('errors.error')} ${e.response.status} - ${textError}`);
                return;
            } catch (err) {
                if (message) this.$message.error(`${message}: ${this.$t('errors.network')}`);
                else this.$message.error(this.$t('errors.network'));
            }
        },
        showErrorMessage(error, isHtml = false) {
            if (!Array.isArray(error)) {
                this.$message({
                    dangerouslyUseHTMLString: isHtml,
                    message: error,
                });
                return;
            }
            const h = this.$createElement;
            const errorsList = error.map(err => h('p', null, err));
            this.$message({
                message: h('p', null, errorsList),
                type: 'error',
            });
        },
    },
};
